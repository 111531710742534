"use client";

import { requestSummary } from "@/app/actions";
import { useAuth } from "@clerk/nextjs";
import { FileSearch } from "lucide-react";
import { useEffect, useRef, useState, type RefObject } from "react";
import { useFormState, useFormStatus } from "react-dom";

const normalizeToArxivId = (str: string) => {
  str = str.replace(/^https:\/\/arxiv.org\/[^\/]+\//, "");
  str = str.replace(/v\d+$/, "");
  return str;
};

function SearchBar({
  onPaste,
  sublabel,
  inputRef,
}: {
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  sublabel: string;
  inputRef: RefObject<HTMLInputElement>;
}) {
  const { pending } = useFormStatus();

  return (
    <label className="form-control">
      <label className="input input-lg input-bordered rounded-full flex items-center gap-2">
        <span className="opacity-70">arXiv:</span>
        <input
          ref={inputRef}
          name="arxivId"
          placeholder="2103.03404"
          type="text"
          pattern="^\d{4}\.\d{4,5}(v\d+)?$"
          inputMode="search"
          autoComplete="off"
          className="grow"
          autoFocus
          onPasteCapture={onPaste}
          disabled={pending}
          required
        />
        {pending ? (
          <span className="loading loading-spinner loading-xs"></span>
        ) : (
          <FileSearch className="opacity-30" />
        )}
      </label>
      <div className="label justify-center">
        <span className={`label-text-alt`}>
          {sublabel || (
            <span>
              You can also paste an{" "}
              <a className="link link-accent" href="https://arxiv.org">
                arXiv
              </a>{" "}
              URL here!
            </span>
          )}
        </span>
      </div>
    </label>
  );
}

export default function SearchForm() {
  const [state, action] = useFormState(requestSummary, { m: "" });
  const [, setUrl] = useState("");
  const { userId } = useAuth();

  const ref = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    let text = e.clipboardData.getData("text");

    ref.current?.setCustomValidity("");

    const id = normalizeToArxivId(text.trim());
    setUrl(id);

    (e.target as any)["value"] = id;

    if (!id.match(/^\d{4}\.\d{4,5}$/)) {
      ref.current?.setCustomValidity("Invalid arXiv ID");
      ref.current?.reportValidity();
    }

    ref.current?.addEventListener("input", function t() {
      ref.current?.setCustomValidity("");
      ref.current?.removeEventListener("input", t);
    });

    e.preventDefault();
  };

  const handleSubmit = () => {
    ref.current?.addEventListener("input", function t() {
      ref.current?.setCustomValidity("");
      ref.current?.removeEventListener("input", t);
    });
  };

  useEffect(() => {
    if (state.m === "FILE_TOO_LARGE") {
      ref.current?.setCustomValidity("This paper is too large, max 2.5MB");
      ref.current?.reportValidity();
    } else if (state.m === "FILE_NOT_FOUND") {
      ref.current?.setCustomValidity("Not found on arXiv");
      ref.current?.reportValidity();
    } else if (state.m === "PAYMENT_REQUIRED") {
      ref.current?.setCustomValidity("Not enough credits");
      ref.current?.reportValidity();
    } else if (state.m === "NOT_LOGGED_IN") {
      ref.current?.setCustomValidity(
        "This paper hasn't been summarized yet - sign in to continue"
      );
      ref.current?.reportValidity();
    }
    ref.current?.addEventListener("input", function t() {
      ref.current?.setCustomValidity("");
      ref.current?.removeEventListener("input", t);
    });
  }, [state]);

  return (
    <div className="flex">
      <div className="w-full px-4">
        <form
          ref={formRef}
          action={action}
          className="flex space-x-2"
          onSubmit={handleSubmit}
        >
          <SearchBar onPaste={handlePaste} sublabel={""} inputRef={ref} />
        </form>
      </div>
    </div>
  );
}
