"use client";

import RecentSummaries from "@/components/ui/recent-summaries";
import SearchForm from "../components/ui/search-form";

export default function Home() {
  return (
    <main className="flex pt-4 flex-col items-center">
      <div>
        <SearchForm />
      </div>
      <div className="w-[50%] max-sm:w-3/4 sm:min-w-[50%] mt-10">
        <RecentSummaries />
      </div>
    </main>
  );
}
