"use client";

import { useEffect, useState } from "react";

export default function RecentSummaries() {
  const [list, setList] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    async function getRecentSummaries() {
      const response = await fetch("/api/summaries?t=recent").catch(() => null);
      const { list } = (await response?.json()) ?? {};
      setList(list);
    }

    if (isOpen && list.length === 0) {
      getRecentSummaries();
    }
  }, [isOpen]);

  return (
    <div
      tabIndex={0}
      className={`collapse collapse-arrow bg-base-200 ${
        isOpen ? "collapse-open" : "collapse-close"
      }`}
    >
      <div
        className="collapse-title text-lg font-medium cursor-pointer"
        onClick={toggle}
      >
        Recent summaries
      </div>
      <div className="collapse-content">
        {list?.length > 0 ? (
          <table className="table">
            {/* head */}
            <tbody>
              {/* row 1 */}
              {list.map((item) => (
                <tr key={item.arxivId}>
                  <td>
                    <a href={`/papers/${item.id}`}>{item.title}</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>
            <div className="flex w-full flex-col gap-8 mt-5 mb-5">
              <div className="skeleton h-3 w-full"></div>
              <div className="skeleton h-3 w-full"></div>
              <div className="skeleton h-3 w-full"></div>
              <div className="skeleton h-3 w-full"></div>
              <div className="skeleton h-3 w-full"></div>
              <div className="skeleton h-3 w-full"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
